// Styled facetwp reset filter

document.addEventListener('facetwp-loaded', function () {
  if (document.querySelector('.facetwp-all')) {
    if (document.querySelector('.facetwp-checkbox.checked')) {
      document
        .querySelector('.facetwp-all')
        .classList.remove('filter_selected');
    } else {
      document.querySelector('.facetwp-all').classList.add('filter_selected');
    }
  }
});
