(function ($) {
  const { _x } = wp.i18n;
  let slideBarToggle = null;

  function toggleDropdown(event) {
    event.preventDefault();
    event.stopPropagation();

    const $navWrapper = $('.nav-mobile-wrapper');
    const $toggle = $(this);
    const $li = $toggle.closest('.dropdown');
    const $subnav = $li.children('.dropdown__subnav-wrapper');

    if ($li.hasClass('opened')) {
      $li.removeClass('opened');
      $subnav.slideUp('fast');
    } else {
      $li.addClass('opened');
      $subnav.slideDown('fast');
    }

    if ($navWrapper.hasClass('submenu-opened')) {
      $navWrapper.removeClass('submenu-opened');
    } else {
      $navWrapper.addClass('submenu-opened');
    }
  }

  function prepareDropdownForNav($nav) {
    ///// INIT SLIDER POSITION /////
    $nav.find('.active').addClass('opened');

    $nav
      .find('.dropdown')
      .filter(':not(.opened)')
      .each(function () {
        $(this).children('.dropdown__subnav-wrapper').slideUp(0);
      });

    ///// INIT EVENTS /////
    $nav.find('.dropdown__toggle').on('click', toggleDropdown);
  }

  function closeSlidebarMenu() {
    slideBarToggle.attr('aria-expanded', false);
    $('body').removeClass('mobile-menu-open');
    $('.vtx-burger-label-item').html('Menu');
    $(document).off('click.close_slidebar').off('keyup.close_slidebar');
  }

  function tryClosingSlidebarMenu(event) {
    // Keyboard
    if ('undefined' !== typeof event.keyCode) {
      // ESCAPE
      if (27 === event.keyCode) {
        $('.vtx-burger-container').focus();
        closeSlidebarMenu();
      }
    }
    // Click
    else if (!$(event.target).closest('#mobile-menu-wrapper').length) {
      closeSlidebarMenu();
    }
  }

  function openSlidebarMenu() {
    slideBarToggle.attr('aria-expanded', true);
    $('body').addClass('mobile-menu-open');
    $('.vtx-burger-label-item').html('Fermer');
    $(document)
      .on('click.close_slidebar', tryClosingSlidebarMenu)
      .on('keyup.close_slidebar', tryClosingSlidebarMenu);
  }

  function initMenuMobile() {
    slideBarToggle = $('.vtx-burger-container');

    const $mobileMenu = $('#mobile-menu');

    slideBarToggle.on('click', function (event) {
      event.stopPropagation();

      if ($('body').hasClass('mobile-menu-open')) {
        closeSlidebarMenu();
      } else {
        openSlidebarMenu();
      }
    });

    prepareDropdownForNav($mobileMenu.find('.nav-mobile'));
  }

  function addSubMenuBackBtn() {
    const dropdownSubnavWrapper = $('.nav-mobile-wrapper > ul');
    const backText = _x('Back', 'back item menu', 'vtx');

    dropdownSubnavWrapper.find('.dropdown').each(function () {
      $(this)
        .children('.dropdown__subnav-wrapper')
        .prepend(
          '<li class="nav-item"><div class="nav-item__link-wrapper"><a class="nav-item__link" href="#">' +
            backText +
            '</a></div></li>'
        );
    });

    $('.dropdown__subnav-wrapper > .nav-item').on('click', toggleDropdown);
  }

  $(document).ready(function () {
    initMenuMobile();
    addSubMenuBackBtn();

    $('.nav-mobile-wrapper').addClass('submenu-opened');

    if (
      $('body').hasClass('home') ||
      $('body').hasClass('subsite-section-page')
    ) {
      $('.nav-mobile-wrapper').removeClass('submenu-opened');
    }
  });
})(jQuery);
