import domReady from '@wordpress/dom-ready';

domReady(() => {
  //////////////////// ADD ARROW BUTTON ////////////////////
  const htmlLinks = $('.content-container').find(
    '.btn, .btn--outline, .wp-block-button__link'
  );

  if (htmlLinks) {
    htmlLinks.each(function (index, element) {
      const linkHtml = element.innerHTML;
      const linkSpan = linkHtml + "<span class='btn-arrow'></span>";
      element.innerHTML = linkSpan;
    });
  }
});
