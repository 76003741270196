(function ($) {
  const player = $('.wp-block-video video');

  if (player) {
    //////////////////// ADD ARROW BUTTON ////////////////////
    const videoBlocks = $('.content-container').find(
      '.wp-block-video:not(.testimonial-item__video)'
    );

    videoBlocks.each(function (index, element) {
      const videoBlockHtml = element.innerHTML;
      const videoBlockPlayBtn =
        videoBlockHtml +
        "<div class='video-play-btn'><span class='icon-next-button'></span>" +
        wp.i18n._x('Play video', 'video', 'vtx') +
        '</div>';
      element.innerHTML = videoBlockPlayBtn;
    });

    $(document).on('click', '.wp-block-video video', function () {
      $(this).parent().addClass('video-playing');
      const video = $(this).get(0);
      if (video.paused === false) {
        video.pause();
      } else {
        video.play();
      }

      return false;
    });
  }
})(jQuery);
