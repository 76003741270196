(function () {
  /////////// Sticky menu class////////////
  const htmlContainer = document.querySelector('html');
  const navbar = document.querySelector('.navbar');

  // Get the offset position of the navbar
  const sticky = navbar.offsetTop + 50;

  // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
  function stickyNavbar() {
    if (window.pageYOffset >= sticky) {
      navbar.classList.add('sticky');
      htmlContainer.classList.add('sticky-navbar');
    } else {
      navbar.classList.remove('sticky');
      htmlContainer.classList.remove('sticky-navbar');
    }
  }

  // When the user scrolls the page, execute stickyNavbar
  window.onscroll = function () {
    stickyNavbar();
  };
  /////////// End Sticky menu class////////////

  ///////// End Add attribute to menu item for hover animation /////////
})(jQuery);
