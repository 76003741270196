import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const htmlContainer = document.querySelector('html');
  const alertsBar = document.querySelector('#alerts-bar');
  const alertsBarItems = document.querySelectorAll(
    '.alerts-bar__carousel .swiper-slide'
  );
  const alertsCloseBtn = document.querySelectorAll('.alertsBarCloseBtn');
  let alertSwiper = null;

  if (alertsBar) {
    htmlContainer.classList.add('alerts-bar-active');
    if (1 < alertsBarItems.length) {
      alertSwiper = new Swiper('.alerts-bar__carousel', {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 10000,
        },
        disableOnInteraction: true,
        pagination: {
          el: '.alerts-bar__pagination',
          clickable: true,
        },
        on: {
          slideChangeTransitionStart() {
            if (
              document
                .querySelector('.swiper-slide-active')
                .classList.contains('alerts-bar-bg__vert')
            ) {
              document
                .querySelector('.alerts-bar__navigation')
                .classList.add('alerts-bar__navigation__dark');
            } else {
              document
                .querySelector('.alerts-bar__navigation')
                .classList.remove('alerts-bar__navigation__dark');
            }
          },
        },
      });

      const alertsBarNavigation = document.querySelector(
        '.alerts-bar__navigation'
      );
      const alertsBarNavigationWidth = alertsBarNavigation.offsetWidth;

      const alertsBarSlides = document.querySelectorAll('.swiper-slide');

      if (alertsBarSlides) {
        alertsBarSlides.forEach(function (alertsBarSlidesItem) {
          const alertsBarText =
            alertsBarSlidesItem.querySelector('.alerts-bar__text');

          if (alertsBarText) {
            alertsBarText.style.maxWidth =
              'calc( 100% - 40px - ' + alertsBarNavigationWidth + 'px)';
          }
        });
      }

      const selectorDisableAnimations = document.querySelector(
        '.toggle-accessibility-animation'
      );

      function checkAccessibilityAnimationToggle() {
        if (selectorDisableAnimations.checked !== true) {
          /*alertSwiper.autoplay.start();*/
        } else {
          alertSwiper.autoplay.stop();
        }
      }

      checkAccessibilityAnimationToggle();

      selectorDisableAnimations.addEventListener(
        'change',
        checkAccessibilityAnimationToggle
      );
    }

    if (0 < alertsCloseBtn.length) {
      alertsCloseBtn.forEach(function (closeBtn) {
        closeBtn.addEventListener('click', function () {
          if (alertSwiper !== null) {
            alertSwiper.destroy();
          }
          alertsBar.classList.remove('visible');
          alertsBar.style.maxHeight = '0px';
          htmlContainer.classList.remove('alerts-bar-active');
        });
      });
    }

    function heightAlertBar() {
      const alertHeight = alertsBar.offsetHeight;
      alertsBar.style.setProperty('--AlertHeight', alertHeight + 'px');
      document.documentElement.style.setProperty(
        '--AlertHeight',
        alertHeight + 'px'
      );
    }

    heightAlertBar();

    window.addEventListener('resize', function () {
      heightAlertBar();
    });
  }
});
